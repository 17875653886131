@font-face {
    font-family: 'DaemPothi';
    src: url('../fonts/AKbalthomDaemPothi.woff2') format('woff2'),
        url('../fonts/AKbalthomDaemPothi.woff') format('woff');
    font-style: normal;
    font-weight: 400, 600;
    unicode-range: U+1780-17FF, U+200C, U+25CC, U+21, U+24, U+28, U+29, U+2C, U+2D, U+2E, U+3A, U+3B, U+3F, U+AB, U+BB;
}

@font-face {
	font-family: 'Content';
	font-style: normal;
	font-weight: 400, 600;
	src: local('LAFSFS'), url('../fonts/content-web-v3.7.woff') format('woff');
	unicode-range: U+1780-17FF, U+200C, U+25CC, U+21, U+24, U+28, U+29, U+2C, U+2D, U+2E, U+3A, U+3B, U+3F, U+AB, U+BB;
}